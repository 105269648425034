// noinspection JSUnusedGlobalSymbols

export enum Cadence {
  Daily,
  Weekly,
  Monthly
}

export const CadenceDesc = [
  'Custom Range',
  'Weekly',
  'Monthly'
];

export const CadencePeriodPluralDesc = [
  'Days',
  'Weeks',
  'Months'
];